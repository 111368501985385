import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Box, Typography, Hidden, CircularProgress, styled } from '@mui/material';
import { IoIosCheckmark } from 'react-icons/io';
import { FaIdCard, FaHome, FaCoins, FaQuestionCircle } from 'react-icons/fa';

import { step1Schema, step2Schema, step3Schema, step4Schema } from '@mikra/validation';
import { FormConnect, Input, Checkbox, PhoneInput, Select, LoanInput } from '@components/rhf-mui5';
import Layout from '../layout';
import Wizard, { WizardStep } from '../components/Wizard';
import LocalCommunitySelect from '../components/LocalCommunitySelect';
import Seo from '../components/Seo';
import Upload from '../components/Upload';
import Stepper from '../components/Stepper';
import InputSlider from '../components/InputSlider';
import axios from '../utils/axios';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useFormContext from '../hooks/useFormContext';

const FormWrapper = styled(Box)`
  ${({ theme, overview }) => `
  position: relative;
  z-index: 100;
  max-width: 576px;
  overflow: hidden;
  box-shadow: 0px 4px 50px rgba(1, 3, 60, 0.08);
  background-color: ${theme.palette.background.paper};
  border-radius: 16px;
  height: fit-content;
  padding: ${theme.spacing(7)};
  margin-top: 10vh;

  ${theme.breakpoints.down('xl')} {
    border-top: none;
    border-radius: 0;
    box-shadow: none;
    padding: ${theme.spacing(2)};
    margin-top: 16px;
    padding-bottom: 96px;
  }
`}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  animation-duration: 0.3s;
  animation-name: lineInserted;
  transition: height 0.3s;
`;

const IndexPage = ({ data, location }) => {
  const { strapiKorak3, strapiKorak4, strapiAplikacijaZaKooperante, registry } = data;
  const { state, dispatch } = useFormContext();

  const { user, signInWithCustomToken, isAuthReady } = useFirebaseAuth();
  const { loanApplicationId } = state;

  const { adminData } = registry;

  const promocode = location?.state?.promocode;
  const promoCode = promocode && promocode > 0 ? promocode : null;

  const handleSubmit = async () => {
    await axios.post(`/wizard/confirm`, {
      loanApplicationId,
    });

    dispatch({ reset: true });
    navigate(`/zahtjev-primljen`, { replace: true });
  };

  if (!isAuthReady && !user?.role) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isAuthReady && (!user?.role || user?.role !== 'kooperant')) {
    navigate('/');

    return null;
  }

  return (
    <Layout offset={1}>
      <Box maxWidth={1200} mx="auto" width="100%" mt={[0, 5]}>
        <Box
          display="flex"
          justifyContent={{ xs: 'center', xl: 'space-between' }}
          gap={5}
          px={{ sm: 0, md: 2 }}
          pb={10}
        >
          <Hidden xlDown implementation="css">
            <Box mt="20vh" maxWidth={460} display="flex" flexDirection="column" gap={4}>
              <Typography variant="h4">{strapiAplikacijaZaKooperante.naslov}</Typography>
              <Typography variant="subtitle2">{strapiAplikacijaZaKooperante.opis}</Typography>
              <Box display="flex" borderBottom="1px solid lightgrey" pb={2} mt={2} gap={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaKooperante.stavka1}
                </Typography>
              </Box>
              <Box display="flex" borderBottom="1px solid lightgrey" pb={2} gap={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaKooperante.stavka2}
                </Typography>
              </Box>
              <Box display="flex" borderBottom="1px solid lightgrey" gap={2} pb={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaKooperante.stavka3}
                </Typography>
              </Box>
              <Box display="flex" borderBottom="1px solid lightgrey" gap={2} pb={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaKooperante.stavka4}
                </Typography>
              </Box>
            </Box>
          </Hidden>
          <FormWrapper>
            <Seo title="Zahtjev za kredit" />
            <Wizard onSubmit={handleSubmit} enableLocalStorage>
              <WizardStep
                onSubmit={async (values) => {
                  const cachedData = JSON.parse(localStorage.getItem('values'));
                  if (user && !!cachedData?.loanApplicationId)
                    axios.patch(`/wizard/step1`, { ...values, loanApplicationId: cachedData.loanApplicationId });
                }}
                schema={step1Schema}
              >
                <LoanInput amountProps={{ max: 10000, min: 500, step: 100 }} repaymentProps={{ max: 60 }} />
                <Input label="Namjena" name="loanPurpose" />
              </WizardStep>
              <WizardStep
                onSubmit={async (values) => {
                  if (user && !!JSON.parse(localStorage.getItem('values'))?.loanApplicationId)
                    axios.patch(`/wizard/step2`, {
                      ...values,
                      loanApplicationId: JSON.parse(localStorage.getItem('values'))?.loanApplicationId,
                    });
                  else {
                    const { data } = await axios.post(`/wizard/start/${user.sifra}/kooperant`, {
                      ...state,
                      ...values,
                      promoCode,
                    });
                    const { token, loanApplicationId } = data;
                    const formData = {
                      ...values,
                      loanApplicationId,
                    };
                    localStorage.setItem('values', JSON.stringify(formData));
                    dispatch({ loanApplicationId, promoCode });
                    // await signInWithCustomToken(token);
                  }
                }}
                schema={step2Schema}
              >
                <Input label="Ime" name="firstName" md={6} />
                <Input label="Prezime" name="lastName" md={6} />
                <Input label="Adresa stanovanja" name="address" md={12} />
                <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" md={6} />
                <PhoneInput label="Fiksni telefon (opcionalno)" name="landlinePhone" type="tel" md={6} />
                <Input label="Email (opcionalno)" name="email" md={12} />
              </WizardStep>
              <WizardStep
                onSubmit={async (values) => {
                  if (user && !!JSON.parse(localStorage.getItem('values'))?.loanApplicationId)
                    axios.patch(`/wizard/step3`, {
                      ...values,
                      loanApplicationId: JSON.parse(localStorage.getItem('values'))?.loanApplicationId,
                    });
                }}
                schema={step3Schema}
              >
                <Input label="Mjesečni prihodi" name="monthlyIncome" type="number" />
                <Input label="Prihodi drugih članova domaćinstva" name="monthlyHouseholdIncome" type="number" />

                <Box p={3} border="1px solid #CFD3D7" borderRadius="4px">
                  <FormConnect>
                    <Checkbox
                      label={strapiKorak3.postojeceZaduzenje.zaduzenjeDomacinstvo}
                      name="householdHasExistingLoan"
                    />
                    <Checkbox label={strapiKorak3.postojeceZaduzenje.zaduzenjePodnosioc} name="clientHasExistingLoan" />

                    <Box mb={2} />
                    <Input label="Ukupni iznos mjesečnih kreditnih zaduženja" name="householdExistingLoanAmount" />
                  </FormConnect>
                  <Box textAlign="end" mt={1}>
                    <Typography variant="caption">
                      <FaQuestionCircle /> Ako nemate postojeći kredit unesite 0.
                    </Typography>
                  </Box>
                </Box>
              </WizardStep>
              <WizardStep schema={step4Schema}>
                <Upload
                  name="lkFiles"
                  title={strapiKorak4.lk.naslov}
                  description={strapiKorak4.lk.podnaslov}
                  icon={<FaIdCard />}
                  meta={{
                    doctype: 687,
                    category: 'lkFiles',
                    loanApplicationId,
                  }}
                />
                <Upload
                  name="residenceProofFiles"
                  title={strapiKorak4.boravak.naslov}
                  description={strapiKorak4.boravak.podnaslov}
                  icon={<FaHome />}
                  meta={{
                    doctype: 692,
                    category: 'residenceProofFiles',
                    loanApplicationId,
                  }}
                />
                <Upload
                  name="incomeProofFiles"
                  doctype="695"
                  title={strapiKorak4.primanja.naslov}
                  description={strapiKorak4.primanja.podnaslov}
                  icon={<FaCoins />}
                  meta={{
                    doctype: 695,
                    category: 'incomeProofFiles',
                    loanApplicationId,
                  }}
                />
              </WizardStep>
            </Wizard>
          </FormWrapper>
        </Box>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query KooperantiQuery {
    registry {
      data {
        cities {
          text
          value
        }
      }
      adminData {
        cities {
          text
          value
        }
        martialStatus {
          text
          value
        }
        employmentStatus {
          text
          value
        }
        loanPurposes {
          text
          value
        }
        loanType {
          text
          value
        }
        loanProducts {
          text
          value
        }
        payoutType {
          text
          value
        }
        loanPurposeActivities {
          text
          value
        }
        offices {
          text
          value
        }
        genders {
          text
          value
        }
        activationTypes {
          text
          value
        }
        promotions {
          text
          value
        }
      }
    }
    strapiAplikacijaZaKooperante {
      naslov
      podnaslov
      opis
      stavka1
      stavka2
      stavka3
      stavka4
      kooperant {
        naziv
        opis
        tip
        sifra
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 95, height: 460, placeholder: NONE)
            }
          }
        }
      }
    }
    strapiKorak3 {
      postojeceZaduzenje {
        zaduzenje
        zaduzenjeDomacinstvo
        zaduzenjePodnosioc
      }
    }
    strapiKorak4 {
      lk {
        naslov
        podnaslov
        dugmeTekst
      }
      boravak {
        naslov
        podnaslov
        dugmeTekst
      }
      primanja {
        naslov
        podnaslov
        dugmeTekst
      }
    }
  }
`;

export default IndexPage;
